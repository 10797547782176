<template>
    <slot></slot>

    <div v-if="!state.locations_loaded" class="px-4 py-5 sm:p-6">
        <LoadingSection />
    </div>

    <fieldset v-if="state.locations_loaded">
        <div class="space-y-4">

            <label v-if="allow_empty" class="group relative block cursor-pointer rounded-lg border bg-white pr-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between hover:border-blue-500 transition" :class="!state.current_location_id ? 'border-transparent border-blue-500 ring-1 ring-blue-500' : 'border-gray-300'">
                <input v-model="state.current_location_id" type="radio" name="server-size" :value="''" class="sr-only">
                <div class="flex-shrink-0 px-3 flex items-center justify-center">
                    <span class="transition rounded-full h-6 w-6 flex items-center justify-center group-hover:border-blue-500" :class="!state.current_location_id ? 'bg-blue-500' : 'border border-2 border-gray-200'">
                        <CheckIcon v-show="!state.current_location_id" class="text-white h-5 w-5" />
                    </span>
                </div>
                <span class="flex-1 items-center">
                    <span class="flex flex-col text-sm">
                        <span class="font-medium text-gray-900">{{ no_location_label || 'No location' }}</span>
                    </span>
                </span>
            </label>

            <label v-for="(location, index) in state.locations" :key="index" class="group relative block cursor-pointer rounded-lg border bg-white pr-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between hover:border-blue-500 transition" :class="state.current_location_id == location.id ? 'border-transparent border-blue-500 ring-1 ring-blue-500' : 'border-gray-300'">
                <input v-model="state.current_location_id" type="radio" name="server-size" :value="location.id" class="sr-only" :aria-labelledby="`location-${location.id}-name`" :aria-describedby="`location-${location.id}-description`">
                <div class="flex-shrink-0 px-3 flex items-center justify-center">
                    <span class="transition rounded-full h-6 w-6 flex items-center justify-center group-hover:border-blue-500" :class="state.current_location_id == location.id ? 'bg-blue-500' : 'border border-2 border-gray-200'">
                        <CheckIcon v-show="state.current_location_id == location.id" class="text-white h-5 w-5" />
                    </span>
                </div>
                <span class="flex-1 items-center">
                    <span class="flex flex-col text-sm">
                        <span :id="`location-${location.id}-name`" class="font-medium text-gray-900" v-html="location.name"></span>
                        <span :id="`location-${location.id}-description`" class="text-gray-500">
                            <span class="block sm:inline" v-html="location.address_line_1"></span>
                            <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
                            <span class="block sm:inline" v-html="location.town"></span>
                            <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">&middot;</span>
                            <span class="block sm:inline" v-html="location.postcode"></span>
                        </span>
                    </span>
                </span>
                <span class="pointer-events-none absolute -inset-px rounded-lg border-1" :class="state.current_location_id == location.id ? 'border border-blue-500' : 'border-1 border-transparent'" aria-hidden="true"></span>
            </label>

            <label v-if="allow_other" class="group relative block cursor-pointer rounded-lg border bg-white pr-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between hover:border-blue-500 transition" :class="state.current_location_id == 'OTHER' ? 'border-transparent border-blue-500 ring-1 ring-blue-500' : 'border-gray-300'">
                <input v-model="state.current_location_id" type="radio" name="server-size" :value="'OTHER'" class="sr-only">
                <div class="flex-shrink-0 px-3 flex items-center justify-center">
                    <span class="transition rounded-full h-6 w-6 flex items-center justify-center group-hover:border-blue-500" :class="state.current_location_id == 'OTHER' ? 'bg-blue-500' : 'border border-2 border-gray-200'">
                        <CheckIcon v-show="state.current_location_id == 'OTHER'" class="text-white h-5 w-5" />
                    </span>
                </div>
                <span class="flex-1 items-center">
                    <span class="flex flex-col text-sm">
                        <span class="font-medium text-gray-900">{{ 'Other' }}</span>
                    </span>
                </span>
            </label>

        </div>
    </fieldset>
</template>

<script>
import { computed, onMounted, onBeforeMount, reactive, watch } from 'vue'
import { useUserStore } from '@sto/user.store'
import { useAuthStore } from '@sto/auth.store'
import * as LocationService from '@ser/location'

import CheckIcon from '@ico/CheckIcon.vue'
export default {
    props: ['current_location_id', 'allow_other', 'allow_empty', 'no_location_label'],
    components: {
        CheckIcon
    },
    setup(props, {emit}) {

        const USER_STORE = useUserStore()
        const AUTH_STORE = useAuthStore()

        const state = reactive({
            locations_loading: false,
            locations_loaded: false,
            locations: [],
            current_location_id: props.current_location_id
        })

        const setLocations = locations_data => {
            state.locations = locations_data
        }

        const getLocations = async () => {

            if (!AUTH_STORE.user?.id) return

            try {
                const locations = await LocationService.find([{ key: 'user_id', value: AUTH_STORE.user.id}])
                setLocations(locations)

            } catch (e) {
                console.error('LocationService.find error', e)
            } finally {
                state.locations_loading = false
                state.locations_loaded = true
            }

        }

        watch(
            () => state.current_location_id,
            (new_value, old_value) => {
                if (props.allow_other && state.current_location_id == 'OTHER') return emit('location_id_updated', 'OTHER')
                if (props.allow_empty && state.current_location_id == '') return emit('location_id_updated', '')
                if (new_value != old_value) {
                    const location = state.locations.find(l => l.id == state.current_location_id)
                    emit('location_id_updated', location?.id || null)
                }
                console.warn('unhandled location id change from '+old_value+' to '+new_value)
            }
        )

        onBeforeMount(() => {
            getLocations()
        })

        return {
            state
        }
    }
}
</script>