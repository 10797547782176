/**
 * Dependencies
 */
import { createApp } from 'vue'
// https://pinia.vuejs.org/
import { createPinia } from 'pinia'
// https://tailwindcss.com/
import './index.css'
// https://router.vuejs.org/
import { router } from '@lib/router'
// https://github.com/axios/axios
import axios from 'axios'

// import Sentry from your framework SDK (e.g. @sentry/react) instead of @sentry/browser
import * as Sentry from "@sentry/vue";


/**
 * Configuration
 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = import.meta.env.VITE_API_HOST
axios.defaults.withCredentials = true
window.axios = axios

/***
 * Global Components
 */
import App from './App.vue'

import Message from '@glo/Message.vue'
import Badge from '@glo/Badge.vue'
import Status from '@glo/Status.vue'
import Tooltip from '@glo/Tooltip.vue'
import SystemAlerts from '@glo/SystemAlerts.vue'

import FormField from '@glo/FormField.vue'
import FormDisplayData from "@glo/FormDisplayData.vue";
import FormFileUploadDropZone from '@glo/FormFileUploadDropZone.vue'
import FormFileUploadQueue from '@glo/FormFileUploadQueue.vue'
import Fieldset from '@glo/Fieldset.vue'
import SelectUserLocation from '@glo/SelectUserLocation.vue'
import Button from '@glo/Button.vue'
import ButtonConfirm from '@glo/ButtonConfirm.vue'
import LogoMark from '@glo/LogoMark.vue'
import LogoFull from '@glo/LogoFull.vue'
import HasOrganisationRole from '@glo/HasOrganisationRole.vue'
import HasOrganisationScope from '@glo/HasOrganisationScope.vue'
import HasUserRole from '@glo/HasUserRole.vue'
import HasUserScope from '@glo/HasUserScope.vue'
import Modal from '@glo/Modal.vue'
import UserHasAuctioneer from '@glo/UserHasAuctioneer.vue'
import UserHasSellerAccount from '@glo/UserHasSellerAccount.vue'
import UserHasBuyerAccount from '@glo/UserHasBuyerAccount.vue'

import LayoutMainSingle from '@par/layout/LayoutMainSingle.vue'
import LayoutMainMulti from '@par/layout/LayoutMainMulti.vue'
import LayoutMultiSpanOne from '@par/layout/LayoutMultiSpanOne.vue'
import LayoutMultiSpanTwo from '@par/layout/LayoutMultiSpanTwo.vue'
import LayoutMain from '@par/layout/LayoutMain.vue'
import LayoutHeader from '@par/layout/LayoutHeader.vue'
import LayoutContainer from '@par/layout/LayoutContainer.vue'
import LayoutSection from '@par/layout/LayoutSection.vue'

import LoadingSection from '@glo/LoadingSection.vue'
import LoadingOverlay from '@glo/LoadingOverlay.vue'
import EmptyList from '@glo/EmptyList.vue'

import Panel from '@glo/Panel.vue'
import PanelHeader from '@glo/PanelHeader.vue'
import PanelBody from '@glo/PanelBody.vue'
import PanelFooter from '@glo/PanelFooter.vue'

import PaginationOverview from '@glo/PaginationOverview.vue'

import FeatureFieldsEdit from '@glo/FeatureFieldsEdit.vue'
import FeatureFieldEdit from '@glo/FeatureFieldEdit.vue'

import SectionHeading from '@glo/SectionHeading.vue'

import DataList from '@glo/DataList.vue'
import DataListRow from '@glo/DataListRow.vue'
import DataListTitle from '@glo/DataListTitle.vue'
import DataListValue from '@glo/DataListValue.vue'

import Timeline from '@glo/Timeline.vue'
import TimelineEvent from '@glo/TimelineEvent.vue'

import TabLinks from '@glo/TabLinks.vue'
import InlineWidget from '@glo/InlineWidget.vue'

import PercentageDifference from '@glo/PercentageDifference.vue'

import Splash from '@glo/Splash.vue'

import NavigationBackButton from '@glo/NavigationBackButton.vue'

import InlineSeparator from '@glo/InlineSeparator.vue'

// https://github.com/dumptyd/vue-css-donut-chart/blob/next/README.md#installation
import Donut from 'vue-css-donut-chart'
import DonutChart from '@glo/DonutChart.vue'
import LineChart from '@glo/LineChart.vue'

// color picker = https://aesoper101.github.io/vue3-colorpicker/?path=/story/example-introduction--page
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

// https://vue-multiselect.js.org/
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
 
window.Pusher = Pusher


/**
 * store
 */
const store = createPinia()

/**
 * init
 */
const app = createApp(App)

app.use(router)
app.use(store)
app.use(Donut)
app.use(Vue3ColorPicker)
app.component('multiselect', Multiselect);

app.component('Message', Message)
app.component('Badge', Badge)
app.component('Status', Status)
app.component('Tooltip', Tooltip)
app.component('SystemAlerts', SystemAlerts)
app.component('DonutChart', DonutChart)
app.component('LineChart', LineChart)

app.component('FormField', FormField)
app.component('FormDisplayData', FormDisplayData)
app.component('FormFileUploadDropZone', FormFileUploadDropZone)
app.component('FormFileUploadQueue', FormFileUploadQueue)
app.component('SelectUserLocation', SelectUserLocation)
app.component('Fieldset', Fieldset)
app.component('Button', Button)
app.component('ButtonConfirm', ButtonConfirm)
app.component('LogoMark', LogoMark)
app.component('LogoFull', LogoFull)
app.component('HasOrganisationRole', HasOrganisationRole)
app.component('HasOrganisationScope', HasOrganisationScope)
app.component('HasUserRole', HasUserRole)
app.component('HasUserScope', HasUserScope)
app.component('Modal', Modal)
app.component('UserHasAuctioneer', UserHasAuctioneer)
app.component('UserHasSellerAccount', UserHasSellerAccount)
app.component('UserHasBuyerAccount', UserHasBuyerAccount)

app.component('LayoutMainMulti', LayoutMainMulti)
app.component('LayoutMainSingle', LayoutMainSingle)
app.component('LayoutMultiSpanOne', LayoutMultiSpanOne)
app.component('LayoutMultiSpanTwo', LayoutMultiSpanTwo)
app.component('LayoutMain', LayoutMain)
app.component('LayoutHeader', LayoutHeader)
app.component('LayoutContainer', LayoutContainer)
app.component('LayoutSection', LayoutSection)

app.component('LoadingSection', LoadingSection)
app.component('LoadingOverlay', LoadingOverlay)
app.component('EmptyList', EmptyList)

app.component('Panel', Panel)
app.component('PanelHeader', PanelHeader)
app.component('PanelBody', PanelBody)
app.component('PanelFooter', PanelFooter)

app.component('PaginationOverview', PaginationOverview)

app.component('FeatureFieldsEdit', FeatureFieldsEdit)
app.component('FeatureFieldEdit', FeatureFieldEdit)

app.component('SectionHeading', SectionHeading)

app.component('DataList', DataList)
app.component('DataListRow', DataListRow)
app.component('DataListTitle', DataListTitle)
app.component('DataListValue', DataListValue)

app.component('Timeline',Timeline)
app.component('TimelineEvent',TimelineEvent)

app.component('TabLinks',TabLinks)
app.component('InlineWidget', InlineWidget)

app.component('PercentageDifference', PercentageDifference)

app.component('Splash', Splash)

app.component('NavigationBackButton', NavigationBackButton)

app.component('InlineSeparator', InlineSeparator)

app.directive('focus', {
    mounted(el, binding) {
        if (binding?.value === true) el.focus()
    },
    updated(el, binding) {
        if (binding?.value === true) el.focus()
    }
})

app.mount('#app')

Sentry.init({
    app,
    dsn: "https://e7bab4e157e33bdd9e85802ae7053a14@o4505443098165248.ingest.sentry.io/4505878774611968",
    environment: import.meta.env.VITE_ENV_NAME,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^http:\/\/hub\.auction365\.test\/api/,
                /^http:\/\/auction365\.test/,
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: import.meta.env.SENTRY_TRACE_SAMPLE_RATE, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: import.meta.env.VITE_ENV_NAME == 'production'
});
