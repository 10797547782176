<template>
    <button v-on:click="onClick" :type="type ? type : 'button'" :class="`relative ${state.flex_classes} border border-transparent focus:outline-none ${state.presentation_classes} ${state.size_classes}`" :disabled="disabled">
        <AlertWarningIcon v-if="icon == 'warning'" class="absolute h-5 w-5 left-3" />
        <slot></slot>
    </button>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import AlertWarningIcon from '@ico/AlertWarningIcon.vue'
export default {
    props: ['color', 'width', 'type', 'onClick', 'disabled', 'size', 'icon', 'custom_class', 'flex_classes'],
    components: {
        AlertWarningIcon
    },
    setup(props) {

        const state = reactive({
            presentation_classes: computed(() => {

                let class_string = props.width ? props.width : 'w-full'

                if(props.disabled) {
                    switch(props.color) {
                        case 'custom':
                            return props.custom_class || class_string
                        case 'text-blue':
                        case 'text-red':
                            return class_string + ' rounded-md bg-transparent text-gray-300 hover:cursor-not-allowed'
                        case 'blue-outline':
                        case 'blue-100':
                        case 'red':
                        case 'gray':
                        case 'blue':
                        default:
                            return class_string + ' rounded-md border border-1 border-gray-300 bg-gray-300 text-white'
                    }
                }

                switch(props.color) {
                    case 'blue-outline':
                        return class_string + ' rounded-md border border-1 border-blue-600 bg-white text-blue-600 hover:bg-blue-100 focus:ring-blue-500 focus:ring-2 focus:ring-offset-2'
                    case 'text-blue':
                        return class_string + ' rounded-md bg-transparent text-blue-600 hover:text-blue-700 focus:ring-blue-500'
                    case 'text-red':
                        return class_string + ' rounded-md bg-white text-red-600 hover:text-red-700 focus:ring-red-500'
                    case 'blue-100':
                        return class_string + ' rounded-md border border-1 border-blue-100 bg-blue-100 text-blue-700 hover:bg-blue-200 hover:border-blue-200 focus:ring-blue-500 focus:ring-2 focus:ring-offset-2 shadow-sm'
                    case 'red':
                        return class_string + ' rounded-md border border-1 border-red-600 bg-red-600 text-white hover:bg-red-700 hover:border-red-700 focus:ring-red-500 focus:ring-2 focus:ring-offset-2 shadow-sm'
                    case 'gray':
                        return class_string + ' rounded-md border border-1 border-gray-600 bg-gray-600 text-white hover:bg-gray-700 hover:border-gray-700 focus:ring-gray-500 focus:ring-2 focus:ring-offset-2 shadow-sm'
                    case 'custom':
                        return props.custom_class || class_string
                    case 'blue':
                    default:
                        return class_string + ' rounded-md border border-1 border-blue-600 bg-blue-600 text-white hover:bg-blue-700 hover:border-blue-700 focus:ring-blue-500 focus:ring-2 focus:ring-offset-2 shadow-sm'
                }
            }),
            size_classes: computed(() => {

                switch(props.size) {
                    case 'xs':
                        return `px-2 py-1 text-xs font-medium ${!props.icon ? '' : 'sm:pl-10'}`
                    case 'sm':
                        return `px-2.5 py-1.5 text-xs font-medium ${!props.icon ? '' : 'sm:pl-10'}`
                    case 'lg':
                        return `px-6 py-3 text-base font-medium ${!props.icon ? '' : 'sm:pl-10'}`
                    case 'link':
                        return `px-0 py-1 text-sm font-medium ${!props.icon ? '' : 'sm:pl-10'}`
                    case 'custom':
                        return ''
                    case 'md':
                    default:
                        return `py-2 px-4 text-sm font-medium ${!props.icon ? '' : 'sm:pl-10'}`
                }
            }),
            flex_classes: computed(() => {
                return props.flex_classes
                    ? props.flex_classes
                    : 'inline-flex items-center justify-center'
            })
        })

        return {
            state
        }
    }
}
</script>
