<template>
    <!-- Active: "bg-gray-100" -->
    <li class="w-full mb-3" role="option" tabindex="-1">
        <div class="group flex rounded-md w-full text-left transition overflow-hidden" :class="notification.acknowledged ? 'bg-white hover:bg-gray-100' : 'bg-blue-50 hover:bg-blue-100'">
            <button v-on:click="notificationAction" class="flex-auto group flex rounded-md p-3 text-left transition bg-transparent truncate text-ellipsis">
                <div class="flex h-10 w-10 flex-none items-center justify-center rounded-lg" :class="notification.acknowledged ? 'bg-gray-300' : 'bg-blue-500'">
                    <!-- Heroicon name: outline/pencil-square -->
                    <AlertWarningIcon class="h-6 w-6 text-white" />
                </div>
                <div class="ml-4 flex-auto">
                    <!-- Active: "text-gray-900", Not Active: "text-gray-700" -->
                    <p class="text-sm font-medium text-gray-700" v-html="notification.title"></p>
                    <!-- Active: "text-gray-700", Not Active: "text-gray-500" -->
                    <p class="text-sm text-gray-500" v-html="notification.description"></p>
                </div>
            </button>
            <button v-on:click="deleteAlert" class="flex items-center justify-center text-blue-800 opacity-20 py-1 px-2 hover:opacity-100 hover:text-red-600 hover:bg-red-100">
                <TrashIconFill class="h-4 w-4" />
            </button>
        </div>
    </li>
</template>

<script>
import AlertWarningIcon from '@ico/AlertWarningIcon.vue'
import TrashIconFill from '@ico/TrashIconFill.vue'
import { useAlertStore } from '@sto/alert.store'
import { useAuthStore } from '@sto/auth.store'
import { useRouter } from 'vue-router'
import { computed } from 'vue'
export default {
    props: ['notification'],
    components: {
        AlertWarningIcon,
        TrashIconFill
    },
    setup(props) {

        const ALERT_STORE = useAlertStore()
        const ROUTER = useRouter()
        const AUTH_STORE = useAuthStore()

        const auth_user_id = computed(() => {
            return AUTH_STORE.user?.id || null
        })

        const acknowledgeAlert = async () => {
            return await ALERT_STORE.update(props.notification.id, { acknowledged: 1 })
        }

        const deleteAlert = async () => {
            return await ALERT_STORE.delete(props.notification.id)
        }

        const getNavigationAction = () => {

            let navigation_action = {}

            switch(props.notification.type) {
                case 'transfer_request:destination_review':
                    navigation_action.name = 'transfer_request.view'
                    navigation_action.params = {
                        transfer_request_id: props.notification.payload?.transfer_request_id,
                    }
                    break
                case 'transfer_order:origin_view':
                case 'transfer_order:destination_confirmation':
                    navigation_action.name = 'transfer_order.view'
                    navigation_action.params = {
                        asset_id: props.notification.payload?.asset_id,
                        transfer_order_id: props.notification.payload?.transfer_order_id,
                    }
                    break
                case 'asset:new_comment':
                    navigation_action.name = 'vehicle.view'
                    navigation_action.params = {
                        vehicle_id: props.notification.payload?.vehicle_id,
                    }
                    break
                case 'asset:new_provisional_offer':
                    navigation_action.name = 'organisation.vehicle.view'
                    navigation_action.params = {
                        vehicle_id: props.notification.payload?.vehicle_id,
                        activate_tab: 'bids'
                    }
                    break
            }

            return navigation_action
        }
        
        const notificationAction = () => {
            acknowledgeAlert()
            const navigation_action = getNavigationAction()
            ALERT_STORE.setGlobalModal(false)
            //ALERT_STORE.getUserAlerts(auth_user_id.value)
            ROUTER.push(navigation_action)
        }

        return {
            notificationAction,
            deleteAlert
        }
    },
}
</script>