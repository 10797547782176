/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/
// auth::api
import * as Api from './api'
// lodash - https://lodash.com/
import _ from 'lodash'

/*******************************************************************************
 * Services
 ******************************************************************************/

/*******************************************************************************
 * Configuration
 ******************************************************************************/

/*******************************************************************************
 * Transformer prototypes
 ******************************************************************************/

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/**
 * general success
 *
 * @param {obj} data
 * @return {obj} le data
 */
const _onSuccess = data => {

    if( _.get( data, 'data.data' ) ) {
        return _.get( data, 'data.data' )
    }

    return data

}

/**
 * general fail
 *
 * @param {obj} error from a server
 * @return {obj} the error
 */
const _onFailed = error => new Promise(( resolve, reject ) => {

    let error_obj = error.hasOwnProperty( 'response' ) ? error.response : error

    //reject( _.get( error_obj, 'data', null ) || error_obj )

    reject(error_obj)

})

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * list the assets
 * @param params
 * @returns {Promise<obj>}
 */
export const list = async params =>
    Api.list(params)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * create a general asset
 * @param data
 * @returns {Promise<obj>}
 */
export const create = async data =>
    Api.create(data)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get this user's associated vehicle assets
 *
 * @return {promise}
 */
export const userVehicles = (page = 1, per_page = 30) =>
    Api.userVehicles(page, per_page)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get this user's vehicle inventory
 *
 * @return {promise}
 */
export const dealerVehicleInventory = () =>
    Api.dealerVehicleInventory()
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get an asset by ID
 *
 * @return {promise}
 */
export const show = asset_id =>
    Api.show(asset_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update an asset by ID
 *
 * @return {promise}
 */
export const update = (asset_id, asset_data) =>
    Api.update(asset_id, asset_data)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateStatus = async (asset_id, status_data) =>
    Api.updateStatus(asset_id, status_data)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get an asset's transfer orders
 *
 * @return {promise}
 */
export const transferOrders = (organisation_id, asset_id) =>
    Api.transferOrders(organisation_id, asset_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const transferRequests = async (organisation_id, asset_id, params) =>
    Api.transferRequests(organisation_id, asset_id, params)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get an asset's lots
 *
 * @return {promise}
 */
export const lots = asset_id =>
    Api.lots(asset_id)
        .then( _onSuccess )
        .catch( _onFailed )


/**
 * gget the organisations that have custody over a user's assets
 *
 * @return {promise}
 */
export const userAssetCustody = user_id =>
    Api.userAssetCustody(user_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * create a vehicle for na organisation
 */
export const createVehicle = (organisation_id, payload) =>
    Api.createVehicle(organisation_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * update an asset's location
 * @param {*} asset_id 
 * @param {*} location_id 
 * @returns 
 */
export const location = (asset_id, location_id) =>
    Api.location(asset_id, location_id)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * save a media attachment to the asset
 * @param {*} asset_id 
 * @param {*} payload 
 * @returns 
 */
export const addMedia = async (asset_id, payload) =>
    Api.addMedia(asset_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

/**
 * get the media for a given asset
 * @param {*} asset_id 
 * @param {*} params 
 * @returns 
 */
export const getMedia = async (asset_id, params) =>
    Api.getMedia(asset_id, params)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateMedia = async (media_id, asset_id, data) =>
    Api.updateMedia(media_id, asset_id, data)
        .then( _onSuccess )
        .catch( _onFailed )

export const deleteMedia = async (media_id, asset_id) =>
    Api.deleteMedia(media_id, asset_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const createTransferOrder = async payload =>
    Api.createTransferOrder(payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const createTransferOrderForDealerToAuctionDelivery = async payload =>
    Api.createTransferOrderForDealerToAuctionDelivery(payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const updateTransferOrder = async (transfer_order_id, payload) =>
    Api.updateTransferOrder(transfer_order_id, payload)
        .then( _onSuccess )
        .catch( _onFailed )

export const getTransferOrderByOrganisation = async (organisation_id, asset_id, transfer_order_id) =>
    Api.getTransferOrderByOrganisation(organisation_id, asset_id, transfer_order_id)
        .then( _onSuccess )
        .catch( _onFailed )

export const getTransferRequests = async params =>
    Api.getTransferRequests(params)
        .then(_onSuccess)
        .catch(_onFailed)

/**
 * get an individual transfer request
 * @param {int} transfer_request_id 
 * @returns 
 */
export const getTransferRequest = async transfer_request_id =>
    Api.getTransferRequest(transfer_request_id)
        .then(_onSuccess)
        .catch(_onFailed)

/**
 * update a transfer request
 * @param {int} transfer_request_id 
 * @param {obj} payload 
 * @returns 
 */
export const updateTransferRequest = async (transfer_request_id, payload) =>
    Api.updateTransferRequest(transfer_request_id, payload)
        .then(_onSuccess)
        .catch(_onFailed)

/**
 * create a transfer request
 * @param {obj} payload 
 * @returns 
 */
export const createTransferRequest = async payload =>
    Api.createTransferRequest(payload)
        .then(_onSuccess)
        .catch(_onFailed)

export const transferableAssets = async organisation_id =>
    Api.transferableAssets(organisation_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const userTransferableAssets = () =>
    Api.userTransferableAssets()
        .then(_onSuccess)
        .catch(_onFailed)

export const transferRequestAssets = async (transfer_request_id, payload) =>
    Api.transferRequestAssets(transfer_request_id, payload)
        .then(_onSuccess)
        .catch(_onFailed)

export const sendTransferRequest = async transfer_request_id =>
    Api.sendTransferRequest(transfer_request_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const createTransferOrderForRequest = async (transfer_request_id, payload) =>
    Api.createTransferOrderForRequest(transfer_request_id, payload)
        .then(_onSuccess)
        .catch(_onFailed)

export const getTransferOrder = async transfer_order_id =>
    Api.getTransferOrder(transfer_order_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const getTransferOrders = async params =>
    Api.getTransferOrders(params)
        .then(_onSuccess)
        .catch(_onFailed)

export const removeTransferRequestAsset = async (transfer_request_id, asset_id) =>
    Api.removeTransferRequestAsset(transfer_request_id, asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const transferReceived = async transfer_order_id =>
    Api.transferReceived(transfer_order_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const cancelTransferRequest = async (organisation_id, transfer_request_id) =>
    Api.cancelTransferRequest(organisation_id, transfer_request_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const getComments = async asset_id =>
    Api.getComments(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)
    
export const createComment = async (asset_id, comment_data) =>
    Api.createComment(asset_id, comment_data)
        .then(_onSuccess)
        .catch(_onFailed)

export const getProvisionalOffers = async asset_id =>
    Api.getProvisionalOffers(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const createProvisionalOffer = async (asset_id, data) =>
    Api.createProvisionalOffer(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)
        
export const acceptProvisonalOffer = async (asset_id, offer_id) =>
    Api.acceptProvisonalOffer(asset_id, offer_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const rejectProvisonalOffer = async (asset_id, offer_id) =>
    Api.rejectProvisonalOffer(asset_id, offer_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const rejectProvisonalOfferWithCounter = async (asset_id, offer_id, data) =>
    Api.rejectProvisonalOfferWithCounter(asset_id, offer_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const withdrawProvisonalOffer = async (asset_id, offer_id) =>
    Api.withdrawProvisonalOffer(asset_id, offer_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const storeFeatureValue = async (asset_id, data) =>
    Api.storeFeatureValue(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const updateFeatureValue = async (asset_id, feature_value_id, data) =>
    Api.updateFeatureValue(asset_id, feature_value_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const getFeatureFields = async asset_id => 
    Api.getFeatureFields(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const assignLotNumber = async (asset_id, data) =>
    Api.assignLotNumber(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const changeLotNumber = async (asset_id, data) =>
    Api.changeLotNumber(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const removeLotNumber = async (asset_id, data) =>
    Api.removeLotNumber(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const addNewLink = async (asset_id, data) =>
    Api.addNewLink(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

export const archiveOrganisationAsset = async (organisation_id, asset_id) =>
    Api.archiveOrganisationAsset(organisation_id, asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const archiveUserAsset = async (user_id, asset_id) =>
    Api.archiveUserAsset(user_id, asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const deleteOrganisationAsset = async (organisation_id, asset_id) =>
    Api.deleteOrganisationAsset(organisation_id, asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const getCommentParticipants = async asset_id =>
    Api.getCommentParticipants(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const getDefaultBidIncrement = async asset_id =>
    Api.getDefaultBidIncrement(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const received = async asset_id =>
    Api.received(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const assignAccount = async (asset_id, data) =>
    Api.assignAccount(asset_id, data)
        .then(_onSuccess)
        .catch(_onFailed)

/**
 * get an asset's sale history
 * @param asset_id
 * @returns {Promise<obj>}
 */
export const saleHistory = async asset_id =>
    Api.saleHistory(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const acceptProvisionalOffer = async (asset_id, lot_id, bid_id) =>
    Api.acceptProvisionalOffer(asset_id, lot_id, bid_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const rejectProvisionalOffer = async (asset_id, lot_id, bid_id) =>
    Api.rejectProvisionalOffer(asset_id, lot_id, bid_id)
        .then(_onSuccess)
        .catch(_onFailed)

export const exportMedia = async asset_id =>
    Api.exportMedia(asset_id)
        .then(_onSuccess)
        .catch(_onFailed)