import { defineStore } from 'pinia'

import * as LoanService from '@ser/loan'

import { shallowRef } from 'vue'

import LoanApplicationStepLender from '@par/loan/LoanApplicationStepLender.vue'
import LoanApplicationStepBuyer from '@par/loan/LoanApplicationStepBuyer.vue'
import LoanApplicationStepSale from '@par/loan/LoanApplicationStepSale.vue'
import LoanApplicationStepVehicle from '@par/loan/LoanApplicationStepVehicle.vue'
import LoanApplicationStepItems from '@par/loan/LoanApplicationStepItems.vue'
import LoanApplicationStepSummary from '@par/loan/LoanApplicationStepSummary.vue'

export const useLoanStore = defineStore('loan', {

    state: () => {
        return {
            loan: null,
            loan_events: [],
            loan_records: [],
            loan_error_heading: '',
            loan_error_message: '',
            loan_loaded: false,
            auctioneer_lenders: [],
            auctioneer_configurations: [],
            active_step_id: 'lender',
            application_steps_base: [
                {
                    id: 'lender',
                    name: 'Finance Provider',
                    description: 'Choose a finance provider for the loan',
                    complete: false,
                    status: 'current',
                    component: shallowRef(LoanApplicationStepLender)
                },
                {
                    id: 'buyer',
                    name: 'Buyer Details',
                    description: 'The loan applicant\'s details',
                    complete: false,
                    status: 'upcoming',
                    component: shallowRef(LoanApplicationStepBuyer)
                },
                {
                    id: 'sale',
                    name: 'Sale',
                    description: 'Sale information',
                    complete: false,
                    status: 'upcoming',
                    component: shallowRef(LoanApplicationStepSale)
                },
                {
                    id: 'vehicle',
                    name: 'Vehicle',
                    description: 'Vehicle to be financed',
                    complete: false,
                    status: 'upcoming',
                    component: shallowRef(LoanApplicationStepVehicle)
                },
                {
                    id: 'items',
                    name: 'Additional Items',
                    description: 'Add extra items and services to the loan application',
                    complete: false,
                    status: 'upcoming',
                    component: shallowRef(LoanApplicationStepItems)
                },
                {
                    id: 'summary',
                    name: 'Summary',
                    description: 'Review and submit the loan application',
                    complete: false,
                    status: 'upcoming',
                    component: shallowRef(LoanApplicationStepSummary)
                },
            ],
            user_loans: [],
            user_loans_pagination: {
                total: null,
                current_page: 1,
                per_page: 10,
                last_page: null
            }
        }
    },

    getters: {
        application_steps: state => state.application_steps_base,
        current_active_step: state => {
            let current_step_index = state.application_steps_base.findIndex(s => s.status == 'current')
            current_step_index = current_step_index ?? state.application_steps_base - 1
            return state.application_steps_base[current_step_index].id

        },
    },

    actions: {

        resetStore() {
            this.loan = null
            this.loan_events = []
            this.loan_records = []
            this.loan_error_heading = ''
            this.loan_error_message = ''
            this.loan_loaded = false
            this.auctioneer_lenders = []
            this.auctioneer_configurations = []
            this.active_step_id = 'lender'
            this.application_steps_base.forEach((step, index) => {
                step.status = index === 0 ? 'current' : 'upcoming'
                step.complete = false
            })
        },

        setStepsState(loan) {

            if (loan.lender_id) {
                const lender_step_index = this.application_steps_base.findIndex(s => s.id == 'lender')
                this.application_steps_base[lender_step_index].status = 'complete'
                this.application_steps_base[lender_step_index].complete = true
            }

            if (loan.buyer_name &&
                loan.buyer_account_code &&
                loan.dealer_ref) {

                const buyer_step_index = this.application_steps_base.findIndex(s => s.id == 'buyer')
                this.application_steps_base[buyer_step_index].status = 'complete'
                this.application_steps_base[buyer_step_index].complete = true
            }

            if (loan.sale_ref &&
                loan.sale_date) {
                    
                const sale_step_index = this.application_steps_base.findIndex(s => s.id == 'sale')
                this.application_steps_base[sale_step_index].status = 'complete'
                this.application_steps_base[sale_step_index].complete = true
            }

            if (Array.isArray(loan.items) && 
                loan.items.length > 0 ) {
                
                const asset_item = loan.items.find(i => i.type == 'asset')
                // check for a vin associated to the loan
                if (asset_item?.asset?.vehicle?.vin || asset_item?.asset?.sub_asset?.vin) {
                    const vehicle_step_index = this.application_steps_base.findIndex(s => s.id == 'vehicle')
                    this.application_steps_base[vehicle_step_index].status = 'complete'
                    this.application_steps_base[vehicle_step_index].complete = true
                }

                const misc_items = loan.items.filter(i => i.type == 'misc')
                if (Array.isArray(misc_items) && misc_items.length > 0) {
                    const items_step_index = this.application_steps_base.findIndex(s => s.id == 'items')
                    this.application_steps_base[items_step_index].status = 'complete'
                    this.application_steps_base[items_step_index].complete = true
                }
            }

            if (loan.status == 'SAVED') {
                const items_alt_step_index = this.application_steps_base.findIndex(s => s.id == 'items')
                this.application_steps_base[items_alt_step_index].status = 'complete'
                this.application_steps_base[items_alt_step_index].complete = true

                const summary_step_index = this.application_steps_base.findIndex(s => s.id == 'summary')
                this.application_steps_base[summary_step_index].status = 'complete'
                this.application_steps_base[summary_step_index].complete = true
            }

            const first_incomplete_step = this.application_steps_base.findIndex(s => s.complete == false)
            // activate the first avaiable step OR the final step if all completes
            if (!isNaN(first_incomplete_step) && this.application_steps_base[first_incomplete_step]) {
                this.application_steps_base[first_incomplete_step].status = 'current'
            } else {
                this.application_steps_base[(this.application_steps_base.length - 1)].status = 'current'
            }
        },

        /**
         * get loan
         * 
         * @param {int} loan_id
         * @param {bool} set_state
         */
        async show(loan_id, set_state) {

            if (this.loan?.id && (this.loan?.id != loan_id)) this.resetStore()

            try {
                this.clearLoanError()
                const loan = await LoanService.show(loan_id)
                if (set_state) this.setStepsState(loan)
                this.loan = loan
                this.loan_loaded = true
            } catch (error) {
                console.error('LoanService.show error', error)
                switch(error?.status) {
                    case 404:
                        this.setLoanError('Loan Not Found', 'We couldn\'t find this loan in the system. Please head back to the vehicle finance page to find the loan you\'re looking for.')
                        break
                    case 403:
                        this.setLoanError('Permission Denied', 'You do not have permission to edit this loan. Please head back to the vehicle finance page to find the loan you\'re looking for.')
                        break
                    default:
                        this.setLoanError('Well this is awkward!', 'Sorry, there was an error retrieving this loan\'s data. Please head back to the vehicle finance page to find the loan you\'re looking for.')
                }
            }
        },

        /**
         * update a loan
         * 
         * @param {int} loan_id
         * @param {obj} payload
         */
         async update(loan_id, payload) {
            try {
                const update = await LoanService.update(loan_id, payload)
                for (let p in payload) {
                    this.loan = this.loan || {}
                    this.loan[p] = payload[p]
                }
            } catch (error) {
                console.error('LoanService.show error', error)
            }
        },

        /**
         * delete a loan
         * 
         * @param {int} loan_id
         */
         async remove(loan_id, auctioneer_id) {
            try {
                const remove = await LoanService.remove(loan_id, auctioneer_id)
            } catch (error) {
                console.error('LoanService.remove error', error)
            }
        },

        /**
         * create or update a vehicle for a specified loan
         * 
         * @param {int} loan_id
         * @param {obj} payload
         */
        async createOrUpdateVehicle(loan_id, payload) {
            try {
                const loan = await LoanService.createOrUpdateVehicle(loan_id, payload)
                if (!loan) throw 'Unexpected response'
                for (let p in payload) {
                    this.loan = this.loan || {}
                    this.loan[p] = loan[p]
                }
            } catch (error) {
                console.error('LoanService.show createOrUpdateVehicle', error)
            }
        },

        /**
         * create a loan item
         * 
         * @param {int} loan_id
         * @param {obj} payload
         */
        async createItem(loan_id, auctioneer_id, payload) {
            try {
                const item = await LoanService.createItem(loan_id, auctioneer_id, payload)
                if (!item) throw 'Unexpected response'
                const loan = await LoanService.show(loan_id)
                this.loan = loan
            } catch (error) {
                console.error('LoanService.show createOrUpdateVehicle', error)
            }
        },

        /**
         * delete a loan item
         * 
         * @param {int} loan_id
         * @param {int} item_id
         */
        async deleteItem(loan_id, auctioneer_id, item_id) {
            try {
                const item = await LoanService.deleteItem(loan_id, auctioneer_id, item_id)
                const loan = await LoanService.show(loan_id)
                this.loan = loan
            } catch (error) {
                console.error('LoanService.show createOrUpdateVehicle', error)
            }
        },

        /**
         * get the lenders for an organisation
         */
        async organisationLenders(organisation_id) {
            try {
                const lenders = await LoanService.organisationLenders(organisation_id)
                this.auctioneer_lenders = lenders
            } catch (error) {

            }
        },

        /**
         * get the loan meta for an organisation
         */
        async auctioneerMeta(auctioneer_id) {

            try {
                const meta = await LoanService.auctioneerMeta(auctioneer_id)

                this.auctioneer_configurations = meta?.configurations || []
                this.auctioneer_lenders = meta?.lenders || []
            } catch (error) {

            }
        },

        /**
         * activate a specific step
         * 
         * @param {str} step_id for the target step
         */
        activateStep(step_id) {
            const current_step_index = this.application_steps_base.findIndex(s => s.status == 'current')
            this.application_steps_base[current_step_index].status = this.application_steps_base[current_step_index].complete ? 'complete' : 'upcoming'
            const target_step_index = this.application_steps_base.findIndex(s => s.id == step_id)
            if (!isNaN(target_step_index) && target_step_index >= 0) this.application_steps_base[target_step_index].status = 'current'
        },

        /**
         * activate tne next step in the arrray
         */
        activateNextStep() {
            const current_step_index = this.application_steps_base.findIndex(s => s.status == 'current')
            // complete this step
            this.application_steps_base[current_step_index].complete = true
            this.application_steps_base[current_step_index].status = 'complete'
            // not the final step
            if (current_step_index < this.application_steps_base.length) {
                //this.active_step_id = this.application_steps_base[current_step_index + 1].id
                this.application_steps_base[current_step_index + 1].status = 'current'
            } else {
                // TODO handle final step sceanrio
            }
        },

        /**
         * activate the previous step
         */
        activatePrevStep() {
            const current_step_index = this.application_steps_base.findIndex(s => s.status == 'current')
            // not the first step
            if (current_step_index > 0) {
                this.application_steps_base[current_step_index].status = this.application_steps_base[current_step_index].complete ? 'complete' : 'upcoming'
                this.application_steps_base[current_step_index - 1].status = 'current'
            }
        },

        /**
         * set a loan error
         */
        setLoanError(heading, message) {
            this.loan_error_heading = heading || ''
            this.loan_error_message = message || ''
        },

        /**
         * clear loan errors
         */
        clearLoanError() {
            this.setLoanError()
        },

        /**
         * prepare an invoice to be submitted
         */
        prepareInvoice(loan_id, organisation_id) {
            return LoanService.prepareInvoice(loan_id, organisation_id)
        },

        /**
         * submit a loan applcaiton
         */
        submitApplication(loan_id, auctioneer_id) {
            return LoanService.submitApplication(loan_id, auctioneer_id)
        },

        /**
         * get the events for a loan
         */
        async getEvents(loan_id, auctioneer_id) {
            const loan_events = await LoanService.events(loan_id, auctioneer_id)
            this.loan_events = loan_events || []
        },

        /**
         * get the recrods for a loan
         */
        async getRecords(loan_id, auctioneer_id) {
            const loan_records = await LoanService.records(loan_id, auctioneer_id)
            this.loan_records = loan_records || []
        }
    }

})