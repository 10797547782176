/*******************************************************************************
 * Dependency Imports
 ******************************************************************************/

// Helpers
import { getEndpoint } from '@lib/helpers'
import axios from 'axios'

/*******************************************************************************
 * Private methods
 ******************************************************************************/

/*******************************************************************************
 * Public methods
 ******************************************************************************/

/**
 * get the auctioneer's sale codes
 * @returns 
 */
export const find = async (params = []) => {

    return new Promise(( resolve, reject ) => {

        axios({
            method: 'get',
            url: getEndpoint('/locations', params),
            headers: {
                'Accept': 'application/json'
            }
        })
        .then(( response ) => {
            resolve( response )
        })
        .catch(( error ) => {
            reject( error )
        })
    })
}