// import vue-router
import { createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@sto/auth.store'
import { useSystemStore } from '@sto/system.store'
import { useCommentStore } from '@sto/comment.store'

export const router = createRouter({
    // history mode
    history: createWebHistory(),
    // routes
    routes: [
        {
            path: '/',
            component: () => import('../screens/AuthLayout.vue'),
            children: [
                {
                    path: '/',
                    name: 'public.index',
                    component: () => import('../screens/PublicIndexScreen.vue'),
                    meta: {
                        title: 'Home',
                        protected: false,
                    }
                },
                {
                    path: '/login',
                    name: 'auth.login',
                    component: () => import('../screens/AuthLoginScreen.vue'),
                    meta: {
                        title: 'Log in',
                        protected: false,
                    }
                },
                {
                    path: '/password',
                    name: 'password.index',
                    component: import('../screens/AuthForgotPasswordScreen.vue'),
                    meta: {
                        title: 'Request a Password Reset',
                        protected: false,
                    }
                },
                {
                    path: '/password/forgotten',
                    name: 'password.forgotten',
                    component: () => import('../screens/AuthForgotPasswordScreen.vue'),
                    meta: {
                        title: 'Request a Password Reset',
                        protected: false,
                    }
                },
                {
                    path: '/password/reset/:token',
                    name: 'password.reset',
                    component: () => import('../screens/AuthResetPasswordScreen.vue'),
                    meta: {
                        title: 'Reset Password',
                        protected: false,
                    }
                }
            ]
        },
        /**
         * invite routes
         */
        {
            path: '/invitation',
            component: () => import('../screens/AuthLayout.vue'),
            children: [
                {
                    path: '/invitation/:token/redeem',
                    name: 'invite.redeem',
                    component: () => import('../screens/InviteRedeemScreen.vue'),
                    meta: {
                        title: 'Redeem an Invition',
                        protected: false,
                    }
                },
            ]
        },
        {
            path: '/app',
            component: () => import('../screens/AppLayout.vue'),
            children: [
                {
                    path: '/app',
                    name: 'user.dashboard',
                    component: () => import('../screens/UserDashboardScreen.vue'),
                    meta: {
                        title: 'Dashboard',
                        protected: true,
                    }
                },
                /**********************************************************************
                 * Loans
                 **********************************************************************/
                {
                    path: '/app/loans',
                    name: 'loan.index',
                    component: () => import('../screens/LoanIndexScreen.vue'),
                    meta: {
                        title: 'Loans',
                        protected: true,
                    }
                },
                {
                    path: '/app/loans/:loan_id',
                    name: 'loan.view',
                    component: () => import('../screens/LoanViewScreen.vue'),
                    meta: {
                        title: 'Loan View',
                        protected: true,
                        breadcrumbs: [
                            { name: 'loan.index', label: 'Loans' }
                        ]
                    }
                },
                {
                    path: '/app/loans/:loan_id/application',
                    name: 'loan.application',
                    component: () => import('../screens/LoanApplicationScreen.vue'),
                    meta: {
                        title: 'Loan Application',
                        protected: true,
                        breadcrumbs: [
                            { name: 'loan.index', label: 'Loans' }
                        ]
                    }
                },
                /**********************************************************************
                 * Vehicles
                 **********************************************************************/
                 {
                    path: '/app/vehicles/:vehicle_id/create',
                    name: 'vehicle.create',
                    component: () => import('../screens/VehicleCreateScreen.vue'),
                    meta: {
                        title: 'Add a vehicle',
                        protected: true,
                    }
                },
                {
                    path: '/app/vehicles-dep',
                    name: 'vehicle.index',
                    component: () => import('../screens/VehicleIndexScreen.vue'),
                    meta: {
                        title: 'Vehicle Inventory',
                        protected: true,
                    }
                },
                {
                    path: '/app/vehicles',
                    name: 'vehicle.user.index',
                    component: () => import('../screens/UserVehicleIndexScreen.vue'),
                    meta: {
                        title: 'My Vehicles',
                        protected: true,
                    }
                },
                {
                    path: '/app/organisations/:organisation_id?/vehicles',
                    name: 'organisation.vehicle.list',
                    component: () => import('../screens/OrganisationVehiclesListScreen.vue'),
                    meta: {
                        title: 'Organisation Vehicles',
                        protected: true,
                    }
                },
                {
                    path: '/app/organisations/:organisation_id?/vehicles/:vehicle_id',
                    name: 'organisation.vehicle.view',
                    component: () => import('../screens/UserVehicleViewScreen.vue'),
                    meta: {
                        title: 'Organisation Vehicle',
                        protected: true,
                    }
                },
                // TODO deprecate...
                {
                    path: '/app/organisations/:organisation_id?/assets/:asset_id/send-to-auction',
                    name: 'organisation.asset.transfer_vehicle_dealership_to_auctioneer',
                    component: () => import('../screens/OrganisationVehicleDealershipToAuctioneerScreen.vue'),
                    meta: {
                        title: 'Asset Transfer Orders',
                        protected: true,
                    }
                },
                // TODO: deprecate...
                {
                    path: '/app/organisations/:organisation_id?/assets/:asset_id/transfer-order/:transfer_order_id/review',
                    name: 'organisation.asset.transfer_order.review',
                    component: () => import('../screens/ReviewTransferOrderScreen.vue'),
                    meta: {
                        title: 'Review Asset Transfer Order',
                        protected: true,
                    }
                },
                {
                    path: '/app/vehicles/:vehicle_id',
                    name: 'vehicle.view',
                    component: () => import('../screens/UserVehicleViewScreen.vue'),
                    meta: {
                        title: 'Vehicle',
                        protected: true,
                    }
                },
                /**********************************************************************
                 * General Items
                 **********************************************************************/
                {
                    path: '/app/items',
                    name: 'items.index',
                    component: () => import('../screens/GeneralItemIndexScreen.vue'),
                    meta: {
                        title: 'My Items',
                        protected: true,
                    }
                },
                {
                    path: '/app/items/:asset_id/create',
                    name: 'items.create',
                    component: () => import('../screens/GeneralItemCreateScreen.vue'),
                    meta: {
                        title: 'Create an Item',
                        protected: true,
                    }
                },
                {
                    path: '/app/items/:asset_id',
                    name: 'items.view',
                    component: () => import('../screens/GeneralItemViewScreen.vue'),
                    meta: {
                        title: 'View an Item',
                        protected: true,
                    }
                },
                /**********************************************************************
                 * Transfer Orders
                 **********************************************************************/
                {
                    path: '/app/transfer-orders',
                    name: 'transfer_order.index',
                    component: () => import('../screens/TransferOrderIndexScreen.vue'),
                    meta: {
                        title: 'Transfer Orders',
                        protected: true,
                    }
                },
                {
                    path: '/app/my-transfers-in',
                    name: 'transfers.in',
                    component: () => import('../screens/TransferInScreen.vue'),
                    meta: {
                        title: 'Transfers In',
                        protected: true,
                    }
                },
                {
                    path: '/app/my-transfers-out',
                    name: 'transfers.out',
                    component: () => import('../screens/TransferOutScreen.vue'),
                    meta: {
                        title: 'Transfers Out',
                        protected: true,
                    }
                },
                {
                    path: '/app/assets/:asset_id/transfer-order/:transfer_order_id',
                    name: 'transfer_order.view',
                    component: () => import('../screens/TransferOrderScreen.vue'),
                    meta: {
                        title: 'Asset Transfer Order',
                        protected: true,
                    }
                },
                // possibly deprecate
                {
                    path: '/app/transfer-request/:transfer_request_id/send-to-auction',
                    name: 'transfer_request.create',
                    component: () => import('../screens/TransferRequestCreateScreen.vue'),
                    meta: {
                        title: 'Arrange Collection or Delivery',
                        protected: true,
                    }
                },
                {
                    /**
                     * view transfer request through the scope of a specified organisation
                     */
                    path: '/app/transfer-request/:transfer_request_id',
                    name: 'transfer_request.view',
                    component: () => import('../screens/TransferRequestViewScreen.vue'),
                    meta: {
                        title: 'View Transfer Request',
                        protected: true,
                    }
                },
                {
                    /**
                     * edit transfer request through the scope of a specified organisation
                     */
                    path: '/app/transfer-request/:transfer_request_id/edit',
                    name: 'transfer_request.edit',
                    component: () => import('../screens/TransferRequestEditScreen.vue'),
                    meta: {
                        title: 'Edit Transfer Request',
                        protected: true,
                    }
                },
                {
                    // http://auction365.test:5173/app/transfer-request/1/destination-review
                    path: '/app/transfer-request/:transfer_request_id/destination-review',
                    name: 'transfer_request.destination_review',
                    component: () => import('../screens/TransferRequestDestinationReviewScreen.vue'),
                    meta: {
                        title: 'Review Transfer Request',
                        protected: true,
                    }
                },
                {
                    // http://auction365.test:5173/app/transfer-request/1/assets/1,2,3/schedule-collection
                    path: '/app/transfer-request/:transfer_request_id/assets/:asset_ids/schedule-collection',
                    name: 'transfer_request.schedule_collection',
                    component: () => import('../screens/TransferOrderScheduleCollectionScreen.vue'),
                    meta: {
                        title: 'Schedule Collection',
                        protected: true,
                    }
                },
                {
                    // http://auction365.test:5173/app/transfer-request/1/assets/1,2,3/arrange-delivery
                    path: '/app/transfer-request/:transfer_request_id/assets/:asset_ids/arrange-delivery',
                    name: 'transfer_request.arrange_delivery',
                    component: () => import('../screens/TransferOrderArrangeDeliveryScreen.vue'),
                    meta: {
                        title: 'Arrange Delivery',
                        protected: true,
                    }
                },
                {
                    path: '/app/send-vehicles-to-auction',
                    name: 'vehicle.send_to_auction',
                    component: () => import('../screens/SendVehiclesToAuctionScreen.vue'),
                    meta: {
                        title: 'Send Vehicles to Auction',
                        protected: true
                    }
                },
                /**********************************************************************
                 * Sales
                 **********************************************************************/
                {
                    path: '/app/sales',
                    name: 'sales.index',
                    component: () => import('../screens/SalesIndexScreen.vue'),
                    meta: {
                        title: 'Sales',
                        protected: true,
                    }
                },
                {
                    path: '/app/sales/settings',
                    name: 'sales.settings',
                    component: () => import('../screens/SalesSettingsScreen.vue'),
                    meta: {
                        title: 'Sales Settings',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' }
                        ]
                    }
                },
                {
                    path: '/app/sales/types/create',
                    name: 'sales.types.create',
                    component: () => import('../screens/SalesTypeCreateScreen.vue'),
                    meta: {
                        title: 'New Sale Type',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', params: { activate_tab: 'sale_types' }, label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/sections/create',
                    name: 'sales.sections.create',
                    component: () => import('../screens/SalesSectionCreateScreen.vue'),
                    meta: {
                        title: 'New Sale Section',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', params: { activate_tab: 'sale_sections' }, label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/codes/create',
                    name: 'sales.codes.create',
                    component: () => import('../screens/SalesCodeCreateScreen.vue'),
                    meta: {
                        title: 'New Sale Code',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/custom-fields/create',
                    name: 'sales.custom_fields.create',
                    component: () => import('../screens/SalesCustomFieldCreateScreen.vue'),
                    meta: {
                        title: 'New Custom Field',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/custom-fields/:feature_field_id/update',
                    name: 'sales.custom_fields.update',
                    component: () => import('../screens/SalesCustomFieldUpdateScreen.vue'),
                    meta: {
                        title: 'Update Custom Field',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/codes/:sale_code_id',
                    name: 'sales.codes.view',
                    component: () => import('../screens/SalesCodeViewScreen.vue'),
                    meta: {
                        title: 'Sale Code',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/codes/:sale_code_id/update',
                    name: 'sales.codes.update',
                    component: () => import('../screens/SalesCodeUpdateScreen.vue'),
                    meta: {
                        title: 'Update Sale Code',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/sections/:sale_section_id',
                    name: 'sales.sections.view',
                    component: () => import('../screens/SalesSectionViewScreen.vue'),
                    meta: {
                        title: 'Sale Section',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/sections/:sale_section_id/update',
                    name: 'sales.sections.update',
                    component: () => import('../screens/SalesSectionUpdateScreen.vue'),
                    meta: {
                        title: 'Update Sale Section',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/types/:sale_type_id',
                    name: 'sales.types.view',
                    component: () => import('../screens/SalesTypeViewScreen.vue'),
                    meta: {
                        title: 'Sale Type',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/types/:sale_type_id/update',
                    name: 'sales.types.update',
                    component: () => import('../screens/SalesTypeUpdateScreen.vue'),
                    meta: {
                        title: 'Update Sale Type',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                            { name: 'sales.settings', label: 'Sales Settings' }
                        ]
                    }
                },
                {
                    path: '/app/sales/create',
                    name: 'sales.new.create',
                    component: () => import('../screens/SalesCreateScreen.vue'),
                    meta: {
                        title: 'Add a new Sale',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                        ]
                    }
                },
                {
                    path: '/app/sales/:sale_id/configure',
                    name: 'sales.new.configure',
                    component: () => import('../screens/SalesConfigureScreen.vue'),
                    meta: {
                        title: 'Add a new Sale',
                        protected: true,
                        breadcrumbs: [
                            { name: 'sales.index', label: 'Sales' },
                        ]
                    }
                },
                {
                    path: '/app/sales/:sale_id',
                    name: 'sales.view',
                    component: () => import('../screens/SalesShowScreen.vue'),
                    meta: {
                        title: 'The Sale',
                        protected: true,
                    }
                },
                {
                    path: '/app/sales/:sale_id/edit',
                    name: 'sales.edit',
                    component: () => import('../screens/SalesEditScreen.vue'),
                    meta: {
                        title: 'Edit Sale',
                        protected: true,
                    }
                },
                {
                    path: '/app/sales/:sale_id/add-lots',
                    name: 'sales.add_lots',
                    component: () => import('../screens/SalesAddLotsScreen.vue'),
                    meta: {
                        title: 'Add lots to a sale',
                        protected: true,
                    }
                },

                /**********************************************************************
                 * Accounts
                 **********************************************************************/
                {
                    path: '/app/accounts',
                    name: 'accounts.index',
                    component: () => import('../screens/AccountsIndexScreen.vue'),
                    meta: {
                        title: 'Accounts',
                        protected: true,
                    }
                },
                {
                    path: '/app/accounts/create',
                    name: 'accounts.create',
                    component: () => import('../screens/AccountsCreateScreen.vue'),
                    meta: {
                        title: 'Create Account',
                        protected: true,
                        breadcrumbs: [
                            { name: 'accounts.index', label: 'All Accounts' }
                        ]
                    }
                },
                {
                    path: '/app/accounts/:account_id/update',
                    name: 'accounts.update',
                    component: () => import('../screens/AccountsUpdateScreen.vue'),
                    meta: {
                        title: 'Update Account',
                        protected: true,
                    }
                },
                {
                    path: '/app/accounts/:account_id',
                    name: 'accounts.show',
                    component: () => import('../screens/AccountsShowScreen.vue'),
                    meta: {
                        title: 'Account',
                        protected: true
                    }
                },
                /**********************************************************************
                 * Buyers
                 **********************************************************************/
                {
                    path: '/app/buyers',
                    name: 'buyers.index',
                    component: () => import('../screens/BuyerIndexScreen.vue'),
                    meta: {
                        title: 'Buyers',
                        protected: true,
                    }
                },
                {
                    path: '/app/buyers/create',
                    name: 'buyers.create',
                    component: () => import('../screens/BuyerCreateScreen.vue'),
                    meta: {
                        title: 'New Buyer',
                        protected: true,
                        breadcrumbs: [
                            { name: 'buyers.index', label: 'My Buyers' }
                        ]
                    },
                },
                {
                    path: '/app/buyers/:buyer_id',
                    name: 'buyers.show',
                    component: () => import('../screens/BuyerShowScreen.vue'),
                    meta: {
                        title: 'Buyer',
                        protected: true,
                    },
                },
                {
                    path: '/app/buyers/:buyer_id/update',
                    name: 'buyers.update',
                    component: () => import('../screens/BuyerUpdateScreen.vue'),
                    meta: {
                        title: 'Update Buyer',
                        protected: true,
                    },
                },
                /**********************************************************************
                 * Settings
                 **********************************************************************/
                {
                    path: '/app/settings/profile',
                    name: 'settings.profile',
                    component: () => import('../screens/SettingsUserScreen.vue'),
                    meta: {
                        title: 'My Profile',
                        protected: true,
                    }
                },
                {
                    path: '/app/settings/organisations',
                    name: 'settings.organisation.list',
                    component: () => import('../screens/SettingsOrganisationsScreen.vue'),
                    meta: {
                        title: 'My Organisations',
                        protected: true,
                    }
                },
                {
                    path: '/app/settings/organisations/:organisation_id?',
                    name: 'settings.organisation.show',
                    component: () => import('../screens/SettingsOrganisationScreen.vue'),
                    meta: {
                        title: 'Organisation',
                        protected: true,
                    }
                },
                {
                    path: '/app/settings/notifications',
                    name: 'settings.notifications',
                    component: () => import('../screens/SettingsNotificationsScreen.vue'),
                    meta: {
                        title: 'Notifications',
                        protected: true,
                    }
                },
                /**********************************************************************
                 * User Admin
                 **********************************************************************/
                {
                    path: '/app/admin/users',
                    name: 'superadmin.users',
                    component: () => import('../screens/SuperAdminUsersListScreen.vue'),
                    meta: {
                        title: 'Auction365 Users',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/users/:user_id',
                    name: 'superadmin.users.edit',
                    component: () => import('../screens/SuperAdminUsersViewScreen.vue'),
                    meta: {
                        title: 'Manage User',
                        protected: true,
                        super_admin: true,
                        breadcrumbs: [
                            { name: 'superadmin.users', label: 'All Users' }
                        ]
                    }
                },
                {
                    path: '/app/admin/users/create',
                    name: 'superadmin.users.create',
                    component: () => import('../screens/SuperAdminUsersCreateScreen.vue'),
                    meta: {
                        title: 'New User',
                        protected: true,
                        super_admin: true,
                        breadcrumbs: [
                            { name: 'superadmin.users', label: 'All Users' }
                        ]
                    }
                },
                {
                    path: '/app/admin/invitations',
                    name: 'superadmin.invites',
                    component: () => import('../screens/SuperAdminInvitesListScreen.vue'),
                    meta: {
                        title: 'All Invitations',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/invitations/create',
                    name: 'superadmin.invites.create',
                    component: () => import('../screens/SuperAdminInvitesCreateScreen.vue'),
                    meta: {
                        title: 'New Invitation',
                        protected: true,
                        super_admin: true,
                        breadcrumbs: [
                            { name: 'superadmin.invites', label: 'All Invitations' }
                        ]
                    }
                },
                {
                    path: '/app/admin/invitations/:invite_id',
                    name: 'superadmin.invites.show',
                    component: () => import('../screens/SuperAdminInvitesShowScreen.vue'),
                    meta: {
                        title: 'Invitations',
                        protected: true,
                        super_admin: true,
                        breadcrumbs: [
                            { name: 'superadmin.invites', label: 'All Invitations' }
                        ]
                    }
                },
                
                {
                    path: '/app/admin/organisations',
                    name: 'superadmin.organisations',
                    component: () => import('../screens/SuperAdminOrganisationsListScreen.vue'),
                    meta: {
                        title: 'Auction365 Organisations',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/organisations/:organisation_id?',
                    name: 'superadmin.organisations.edit',
                    component: () => import('../screens/SuperAdminOrganisationsViewScreen.vue'),
                    meta: {
                        title: 'Manage Organisation',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/organisations/create',
                    name: 'superadmin.organisations.create',
                    component: () => import('../screens/SuperAdminCreateOrganisationScreen.vue'),
                    meta: {
                        title: 'Auction365 New Organisations',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/organisations/:organisation_id?/create',
                    name: 'superadmin.organisations.create-child',
                    component: () => import('../screens/SuperAdminCreateOrganisationScreen.vue'),
                    meta: {
                        title: 'Auction365 New Organisations',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/analytics/sessions',
                    name: 'superadmin.analytics.sessions',
                    component: () => import('../screens/SuperAdminSessionExplorerScreen.vue'),
                    meta: {
                        title: 'Session Explorer',
                        protected: true,
                        super_admin: true
                    }
                },
                {
                    path: '/app/admin/analytics/sessions/:session_id',
                    name: 'superadmin.analytics.sessions.view',
                    component: () => import('../screens/SuperAdminSessionViewScreen.vue'),
                    meta: {
                        title: 'Session',
                        protected: true,
                        super_admin: true,
                        breadcrumbs: [
                            { name: 'superadmin.analytics.sessions', label: 'All Sessions' }
                        ],
                    }
                },
                /**********************************************************************
                 * Reports
                 **********************************************************************/
                {
                    path: '/app/reports',
                    name: 'reports.index',
                    component: () => import('../screens/ReportsIndexScreen.vue'),
                    meta: {
                        title: 'Reports',
                        protected: true,
                        super_admin: false,
                    }
                },
                {
                    path: '/app/reports/sales',
                    name: 'reports.sold-vehicles',
                    component: () => import('../screens/ReportsSoldVehiclesReportScreen.vue'),
                    meta: {
                        title: 'Sold Vehicles Report',
                        protected: true,
                        super_admin: false,
                        breadcrumbs: [
                            { name: 'reports.index', label: 'Reports' }
                        ],
                    }
                },
                /**********************************************************************
                 * Splash Screens
                 **********************************************************************/
                 {
                    path: '/app/forbidden',
                    name: 'splash.forbidden',
                    component: () => import('../screens/SplashForbiddenScreen.vue'),
                    meta: {
                        title: 'Fporbidden',
                        protected: true,
                    }
                },
                
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'error.not-found',
            component: () => import('../screens/404.vue'),
        }
    ]
})

/**
 * take the current path and store it in sessionStorage
 */
const storeTargetPath = full_path => {
    if (typeof full_path == 'string' && full_path.length > 0) sessionStorage.setItem('@A_TARGET_PATH', full_path)
}

// These events fire before each navigation event initialises
router.beforeEach(async (to, from) => {

    const COMMENT_STORE = useCommentStore()
    COMMENT_STORE.resetStore()

    // allow public routes to be accessed unhindered
    if (!to?.meta?.protected) return

    const AUTH = useAuthStore()
    // have the auth store run an auth check which returns a bool value
    const authenticated = await AUTH.authCheck()
    // if we're unauthorised redirect to login
    if (!authenticated) {
        storeTargetPath(to?.fullPath)
        //SYSTEM_STORE.clear()
        return '/login'
    }

    // super admin routes
    if (to?.meta?.super_admin && (!Array.isArray(AUTH.user?.roles) || !AUTH.user?.roles.includes('USER_SUPER_ADMIN'))) {
        return '/app/forbidden'
    }
    const SYSTEM_STORE = useSystemStore()
    SYSTEM_STORE.createInteraction(
        'NAVIGATION_EVENT',
        to?.fullPath,
        to?.fullPath,
        { 
            to_path: to?.fullPath,
            from_path: from?.fullPath,
            to_name: to?.name,
            from_name: from?.name,
            params: to?.params,
            query: to?.query,
            meta: to?.meta
        }
    )

});